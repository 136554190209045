<template>
  <div class="page-notifications dash-cliente">
    <language-selector />

    <div class="container">

      <div class="row">
        <div class="col-12">
          <div class="pd-highlight">
            <h1 class="text-center text-lg-left mb-3">
              {{ $t("dash.activities") }}
            </h1>
          </div>
          <div class="mb-3">
            <h2 class="nav-link mr-4 d-inline-block" :class="{'active': type == 1}" @click="type = 1">{{ $t("dash.notification") }}</h2>
            <h2  class="nav-link d-inline-block" :class="{'active': type == 2}" @click="type = 2">{{ $t("dash.messages") }}</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 py-2">
          <c-box-chat
            v-for="notification in paginated"
            :notification="notification"
            @markRead="markRead"
            :key="notification.id"
          ></c-box-chat>
        </div>

        <div class="col-12 py-2 text-right">
          <p>
            {{ $t("dash.page") }} {{ page + 1 }} {{ $t("dash.de") }}
            {{ pageCount }}
          </p>
        </div>

        <div class="col-12">
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-outline-primary w-auto mx-2"
              @click="prevPage"
              :disabled="page === 0"
            >
              {{ $t("dash.previous") }}
            </button>
            <button
              class="btn btn-outline-primary w-auto mx-2"
              @click="nextPage"
              :disabled="page >= pageCount - 1"
            >
              {{ $t("dash.next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      page: 0,
      size: 5,
      type: 1,
      notifications: [],
    };
  },

  mounted() {
    axios
      .get("/api/v1/notifications/", {
        headers: {
          Authorization: "Bearer " + this.TOKEN,
        },
      })
      .then((response) => {
        this.notifications = response.data.data;
        this.$store.commit("setNotifications", response.data.data || []);
      });
  },

  computed: {
    pageCount() {
      let l = this.notifications.filter((n) => { return n.type == this.type }).length;
      return Math.ceil(l / this.size);
    },
    paginated() {
      const start = this.page * this.size;
      const end = start + this.size;
      return this.notifications.filter((n) => { return n.type == this.type }).slice(start, end);
    },
  },

  methods: {
    nextPage() {
      this.page++;
    },
    prevPage() {
      this.page--;
    },

    markRead(notification) {
      axios
        .put(
          "/api/v1/notifications/" + notification.id + "/mark-read",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.TOKEN,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            this.$router.push({ path: notification.url });
          } else {
            console.log(response.data.error);
          }
        });
    },
  },

  watch: {
    type() {
      this.page = 0;
    }
  }
};
</script>

<style scoped>
  .nav-link {
    border-bottom: solid 3px transparent;
    color: #999;
    cursor: pointer;
  }
  .nav-link.active {
    border-bottom: solid 3px black;
    color: black;
  }

</style>
